import React from 'react';
import { Link } from 'gatsby';

class Nav extends React.Component {
  render() {
    const styles = {
      container: this.props.isDesktop
        ? {}
        : {
            height: this.props.open ? '100%' : 0,
            padding: this.props.open ? '3rem 0' : 0
          }
    };
    const menuItems = this.props.menu.menu.map((value, index) => {
      const animDel = {
        animationDelay: `${(index + 1) * 0.1}s`
      };
      return (
        <Link
          key={index}
          className='headerNav__link'
          to={`/${this.props.menu.menuSlug[index]}`}
          style={animDel}
          onClick={this.props.onClick}
          activeClassName='active'
        >
          {value}
        </Link>
      );
    });
    return (
      <nav className='headerNav' style={styles.container}>
        {this.props.open ? (
          <Link
            to='/'
            className='banner__link'
            onClick={this.props.onClick}
            activeClassName='active'
          >
            <svg
              className='icon'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 640 512'
            >
              <path d='M624 448h-24.68L359.54 117.75l53.41-73.55c5.19-7.15 3.61-17.16-3.54-22.35l-25.9-18.79c-7.15-5.19-17.15-3.61-22.35 3.55L320 63.3 278.83 6.6c-5.19-7.15-15.2-8.74-22.35-3.55l-25.88 18.8c-7.15 5.19-8.74 15.2-3.54 22.35l53.41 73.55L40.68 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM320 288l116.36 160H203.64L320 288z' />
            </svg>
          </Link>
        ) : null}
        {this.props.open ? menuItems : null}
      </nav>
    );
  }
}

export default Nav;
