import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';

import DataItem from '../components/DataItem';
import Layout from '../components/Layout';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Helmet>
        <title>{`${data.site.siteMetadata.title} - ${
          frontmatter.title
        }`}</title>
      </Helmet>
      <main className='content'>
        <div
          className={
            frontmatter.path === '/you'
              ? 'content__inner -you'
              : 'content__inner'
          }
        >
          <h1 className='content__title'>{frontmatter.title}</h1>
          <div
            className='content__dataBox'
            style={{ display: frontmatter.bt1 ? '' : 'none' }}
          >
            <DataItem bigText={frontmatter.bt1} smallText={frontmatter.st1} />
            <DataItem bigText={frontmatter.bt2} smallText={frontmatter.st2} />
            <DataItem bigText={frontmatter.bt3} smallText={frontmatter.st3} />
            <DataItem bigText={frontmatter.bt4} smallText={frontmatter.st4} />
          </div>
          {frontmatter.featuredImage ? (
            <Img sizes={frontmatter.featuredImage.childImageSharp.sizes} />
          ) : (
            ''
          )}
          <div
            className={
              frontmatter.path === '/you'
                ? 'content__textBox -you'
                : 'content__textBox'
            }
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        bt1
        st1
        bt2
        st2
        bt3
        st3
        bt4
        st4
      }
    }
    headerbg: file(relativePath: { eq: "headerimg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    contentbg: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
    gwaaclogow: file(relativePath: { eq: "gwaac-logo-white.svg" }) {
      publicURL
    }
    site: site {
      siteMetadata {
        title
        siteUrl
        description
        menu
        menuSlug
      }
    }
  }
`;
