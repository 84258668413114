import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import ImageHeader from '../components/ImageHeader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Content from '../components/Content';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        headerbg: file(relativePath: { eq: "headerimg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        contentbg: file(relativePath: { eq: "bg.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
        gwaaclogow: file(relativePath: { eq: "gwaac-logo-white.svg" }) {
          publicURL
        }
        site {
          siteMetadata {
            title
            siteUrl
            description
            menu
            menuSlug
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <div className='wrapper'>
          <Helmet>
            <meta
              name='description'
              content={data.site.siteMetadata.description}
            />
            <meta
              name='keywords'
              content='africa, kilimanjaro, fundraising, gwaac, charity'
            />
            <meta name='Classification' content='Fundraising' />
            <meta name='category' content='Fundraising' />
            <title>{data.site.siteMetadata.title}</title>
            <link rel='canonical' href={data.site.siteMetadata.siteUrl} />
          </Helmet>
          <ImageHeader
            bg={data.headerbg}
            gwaaclogo={data.gwaaclogow}
            menu={data.site.siteMetadata}
          />
          <Header menu={data.site.siteMetadata} />
          {children ? children : <Content />}
          <Footer />
        </div>
      </React.Fragment>
    )}
  />
);

export default Layout;
