import React from 'react';

const DataItem = props => (
  <div className={props.noMobile ? 'dataItem noMobile' : 'dataItem'}>
    <div className='dataItem__bigText bigText'>
      <h4>{props.bigText}</h4>
    </div>
    <div className='dataItem__smallText smallText'>
      <p>{props.smallText}</p>
    </div>
  </div>
);

export default DataItem;
