import React from 'react';

const HeroDataItem = props => (
  <div className={props.noMobile ? 'heroDataItem noMobile' : 'heroDataItem'}>
    <div className='bigText'>
      <h4>{props.bigText}</h4>
    </div>
    <div className='smallText'>
      <p>{props.smallText}</p>
    </div>
  </div>
);

export default HeroDataItem;
