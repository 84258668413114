import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
          }
        }
      }
    `}
    render={data => (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__details'>
            <h3>
              <Link to='/'>{data.site.siteMetadata.title}</Link>
            </h3>
            <p>BLISTERS, SCRAMBLED LEGS AND SERIOUS ALTITUDE</p>
            <p>All rights reserved.</p>
          </div>
          <div className='footer__legal'>
            <h3>Legal</h3>
            <p>
              Legally fundraising in aid of Great Western Air Ambulance Charity
              (GWAAC) throughout 2019.
            </p>
            <p>
              If you have any queries regarding this or require further
              information about GWAAC please contact Claire Harmer on{' '}
              <a
                href='tel:0303 4444
              999'
              >
                0303 4444 999
              </a>{' '}
              or <a href='mailto:claire@gwaac.com'>claire@gwaac.com</a>.
            </p>
          </div>
          <div className='footer__contacts'>
            <h3>Contacts</h3>
            <ul>
              <li>
                Mail:
                <br />
                <a href='mailto:kili2019@benszabo.co.uk'>
                  kili2019@benszabo.co.uk
                </a>
              </li>
              <li>
                Insta:
                <br />
                <a
                  href='https://www.instagram.com/b3nk3'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  @b3nk3
                </a>
              </li>
              <li>
                Fundraising through:
                <br />
                <a
                  href='https://kili2019.everydayhero.com/uk/to-the-roof-of-africa-for-gwaac'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  everydayhero
                </a>
              </li>
            </ul>
          </div>
          <div className='footer__credit'>
            <h3>Photo Credit</h3>
            <p>
              Photos by&nbsp;
              <a
                href='https://unsplash.com/photos/7VNZbJoufEQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
                target='_blank'
                rel='noopener noreferrer'
              >
                Tom Cleary
              </a>
              &nbsp; and&nbsp;
              <a
                href='https://unsplash.com/photos/DWXR-nAbxCk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
                target='_blank'
                rel='noopener noreferrer'
              >
                Sergey Pesterev
              </a>
              &nbsp; on Unsplash and from the&nbsp;
              <a
                href='https://greatwesternairambulance.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Great Western Air Ambulance Service
              </a>
              .
            </p>
          </div>
        </div>
      </footer>
    )}
  />
);
// class Footer extends Component {
//   render() {
//     return (

//     );
//   }
// }

export default Footer;
