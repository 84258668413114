import React from 'react';

const Content = () => (
  <main className='content'>
    <div className='content__inner -welcome'>
      <h1 className='content__title'>I've done it!</h1>
      <div className='content__textBox -welcome'>
        <p className='content__text'>
          On the 19th of September, following over 9 hours of strenuous walking, at 9.20am (EAT) me and my fellow trekkers have reached the highest point of Africa, Uhuru Peak.<br /><br />
          Now, I have until the end of the year, to raise the remainder of funds, that will be received by GWAAC in full, as I am self funding the trip.<br />
          <br />
          <br />
          Thanks for visiting my fundraising page. Please have a look around to
          find out more about me, the charity and why I'm doing this.
          <br />
          <br /> Cheer, Ben
        </p>
        <div className='event'>
          <h2>
            Post Kili fundraising and cocktails – 26th October, Loose Cannon
          </h2>
          <br />
          <p className='content__text'>
            I have finally organised an event, go check out the details on{' '}
            <a
              href='https://www.facebook.com/events/588139971722310/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Facebook
            </a>
          </p>
        </div>
      </div>
    </div>
  </main>
);

export default Content;
