import React from 'react';
import HeroDataItem from './HeroDataItem';
import Nav from './Nav';
import MenuBtn from './MenuBtn';
import StateContext from '../context/StateContext';

class Header extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth();
    const d = today.getDate();
    const onlyDate = new Date(y, m, d);
    const goDay = new Date(2019, 8, 13);
    const oneDay = 1000 * 60 * 60 * 24;
    this.daysLeft = Math.round((goDay.getTime() - onlyDate.getTime()) / oneDay);
  }

  componentDidMount() {
    // fetch('https://everydayhero.com/api/v2/pages?id=2925133')
    //   .then(response => response.json())
    //   .then(data => {
    //     let herodata = data.pages[0];
    //     this.setState({ herodata });
    //   })
    //   .catch(error => console.log(error));
    // this.ref = base.syncState('herodata', {
    //   context: this,
    //   state: 'herodata'
    // });
  }

  render() {
    return (
      <StateContext.Consumer>
        {context => (
          <React.Fragment>
            <div className='headerGroup'>
              <div className='headerBar'>
                <h1>
                  Kilimanjaro&nbsp;<span>Trek 2019</span>
                </h1>
                <MenuBtn
                  open={context.menuOpen}
                  onClick={context.handleMenuClick}
                  onKeyDown={e => context.handleMenuKey(e)}
                  color='white'
                  isDesktop={context.isDesktop}
                />
                <Nav
                  open={context.menuOpen}
                  isDesktop={context.isDesktop}
                  menu={this.props.menu}
                  onClick={context.handleLinkClick}
                />
                <div className='heroData'>
                  <HeroDataItem
                    bigText={`£${context.herodata.amount.cents / 100}`}
                    smallText={'raised'}
                  />
                  <HeroDataItem
                    noMobile={true}
                    bigText={`£${context.herodata.target_cents / 100}`}
                    smallText={'my goal'}
                  />
                  <HeroDataItem
                    noMobile={true}
                    bigText={`${
                      (context.herodata.amount.cents /
                        context.herodata.target_cents) *
                        100 <
                      1
                        ? (
                            (context.herodata.amount.cents /
                              context.herodata.target_cents) *
                            100
                          ).toFixed(2)
                        : (
                            (context.herodata.amount.cents /
                              context.herodata.target_cents) *
                            100
                          ).toFixed(0)
                    }%`}
                    smallText={'of my goal'}
                  />
                  <HeroDataItem
                    bigText={this.daysLeft <= 0 ? 'Summited' : this.daysLeft}
                    smallText={`${
                      this.daysLeft <= 0
                        ? 'Mt. Kilimanjaro'
                        : this.daysLeft < 2
                        ? 'day to go'
                        : 'days to go'
                    }`}
                  />
                  <a
                    href={context.herodata.donation_url}
                    target='blank'
                    className='giveNow'
                  >
                    Give Now!
                  </a>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </StateContext.Consumer>
    );
  }
}

export default Header;
