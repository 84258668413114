import React from 'react';

class MenuBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
      color: this.props.color ? this.props.color : 'white'
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const styles = {
      lineTop: {
        backgroundColor: this.state.open ? this.props.color : '',
        transform: this.state.open ? 'rotate(45deg)' : 'none',
        transformOrigin: 'top left'
      },
      lineMiddle: {
        opacity: this.state.open ? 0 : 1,
        transform: this.state.open ? 'translateX(16px)' : 'none'
      },
      lineBottom: {
        backgroundColor: this.state.open ? this.props.color : '',
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)' : 'none',
        transformOrigin: 'top left'
      }
    };
    return (
      <div
        className='menuBtn'
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick();
              }
        }
        onKeyDown={
          this.props.onKeyDown
            ? this.props.onKeyDown
            : () => {
                this.handleClick();
              }
        }
        tabIndex='1'
        style={{ display: `${this.props.isDesktop ? 'none' : ''}` }}
      >
        <div className='menuBtn__line' style={{ ...styles.lineTop }} />
        <div className='menuBtn__line' style={{ ...styles.lineMiddle }} />
        <div className='menuBtn__line' style={{ ...styles.lineBottom }} />
      </div>
    );
  }
}
export default MenuBtn;
