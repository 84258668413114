import React from 'react';
import Img from 'gatsby-image';
import Banner from './Banner';

class ImageHeader extends React.Component {
  render() {
    return (
      <div className='headerImage'>
        <Img fluid={this.props.bg.childImageSharp.fluid} />
        <Banner gwaaclogo={this.props.gwaaclogo} menu={this.props.menu} />
      </div>
    );
  }
}

export default ImageHeader;
